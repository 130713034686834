import { Page404Component } from './authentication/page404/page404.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { Role } from './core/models/role';
const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      {
        path: 'administration',
        canActivate: [AuthGuard],
        data: {
          role: [Role.Admin],
        },        
        loadChildren: () =>
          import('./administration/administration.module').then((m) => m.AdministrationModule),
      },
      {
        path: 'promotions',
        canActivate: [AuthGuard],
        data: {
          role: [Role.Admin,Role.Stores],
        },        
        loadChildren: () =>
          import('./promotions/promotions.module').then((m) => m.PromotionsModule),
      },
      {
        path: 'cms',
        canActivate: [AuthGuard],
        data: {
          role: Role.Admin,
        },        
        loadChildren: () =>
          import('./cms/cms.module').then((m) => m.CmsModule),
      },
      {
        path: 'customers',
        canActivate: [AuthGuard],
        data: {
          role: Role.Admin,
        },        
        loadChildren: () =>
          import('./customers/customers.module').then((m) => m.CustomersModule),
      },
      {
        path: 'vendors',
        canActivate: [AuthGuard],
        data: {
          role: Role.Admin,
        },        
        loadChildren: () =>
          import('./vendors/vendors.module').then((m) => m.VendorsModule),
      },
      {
        path: 'stores',
        canActivate: [AuthGuard],
        data: {
          role: Role.Admin,
        },        
        loadChildren: () =>
          import('./stores/stores.module').then((m) => m.StoresModule),
      },
      {
        path: 'calendar',
        canActivate: [AuthGuard],
        data: {
          role: [Role.Admin,Role.Stores],          
        },
        loadChildren: () =>
          import('./calendar/calendar.module').then((m) => m.CalendarsModule),
      },
      {
        path: 'orders',
        canActivate: [AuthGuard],
        data: {
          role: [Role.Admin,Role.Stores],          
        },
        loadChildren: () =>
          import('./orders/orders.module').then((m) => m.OrdersModule),
      },
      {
        path: 'enquirys',
        loadChildren: () =>
          import('./enquirys/enquirys.module').then((m) => m.EnquirysModule),
      },
      {
        path: 'mystore',
        loadChildren: () =>
          import('./mystore/mystore.module').then((m) => m.MystoreModule),
      },
      {
        path: 'changepassword',
        loadChildren: () =>
          import('./changepassword/changepassword.module').then((m) => m.ChangepasswordModule),
      },
      /* {
        path: 'coursependingproducts',
        canActivate: [AuthGuard],
        data: {
          role: [Role.Admin,Role.Stores],          
        },
        loadChildren: () =>
          import('./coursependingproducts/coursependingproducts.module').then((m) => m.CoursependingproductsModule),
      },
      {
        path: 'courseapproveproducts',
        canActivate: [AuthGuard],
        data: {
          role: [Role.Admin,Role.Stores],          
        },
        loadChildren: () =>
          import('./courseapproveproducts/courseapproveproducts.module').then((m) => m.CourseapproveproductsModule),
      },
      {
        path: 'courserejectproducts',
        canActivate: [AuthGuard],
        data: {
          role: [Role.Admin,Role.Stores],          
        },
        loadChildren: () =>
          import('./courserejectproducts/courserejectproducts.module').then((m) => m.CourserejectproductsModule),
      }, */
      {
        path: 'products',
        canActivate: [AuthGuard],
        data: {
          role: [Role.Admin,Role.Stores],          
        },
        loadChildren: () =>
          import('./products/products.module').then((m) => m.ProductsModule),
      },
      {
        path: 'reports',
        canActivate: [AuthGuard],
        data: {
          role: [Role.Admin,Role.Stores],          
        },
        loadChildren: () =>
          import('./reports/reports.module').then((m) => m.ReportsModule),
      }
      /* ,
      {
        path: 'enquirys',
        canActivate: [AuthGuard],
        data: {
          role: Role.Admin,
        },        
        loadChildren: () =>
          import('./enquirys/enquirys.module').then((m) => m.EnquirysModule),
      } */
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/dashboard/main', pathMatch: 'full' },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        data: {
          role: [Role.Admin],          
        },
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      }
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/dashboardp', pathMatch: 'full' },
      {
        path: 'dashboardp',
        canActivate: [AuthGuard],
        data: {
          role: [Role.Stores],          
        },
        loadChildren: () =>
          import('./dashboardp/dashboardp.module').then((m) => m.DashboardpModule),
      }
    ],
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: '**', component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
