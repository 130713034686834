import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: '',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  },
  {
    path: '/dashboard/main',
    title: 'MENU.DASHBOARD1',
    iconType: 'material-icons-two-tone',
    icon: 'space_dashboard',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  },
  {
    path: '/dashboardp',
    title: 'MENU.DASHBOARD1',
    iconType: 'material-icons-two-tone',
    icon: 'space_dashboard',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Stores'],
    submenu: [],
  },
  {
    path: '',
    title: 'MENU.SYSTEMADMINISTRATION',
    iconType: 'material-icons-two-tone',
    icon: 'ac_unit',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/administration/tenants',
        title: 'MENU.TENANTS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
      },
      {
        path: '/administration/users',
        title: 'MENU.USERS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
      },
      {
        path: '/administration/roles',
        title: 'MENU.ROLES',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['admin'],
        submenu: [],
      },
      /* {
        path: '',
        title: 'MENU.PERMISSIONS',
        iconType: 'material-icons-two-tone',
        icon: 'people_alt',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
      },  
      {
        path: '',
        title: 'MENU.AUDITPOLICY',
        iconType: 'material-icons-two-tone',
        icon: 'people_alt',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
      },  
      {
        path: '',
        title: 'MENU.ACCOUNTTPOLICY',
        iconType: 'material-icons-two-tone',
        icon: 'people_alt',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
      },  
      {
        path: '/admin/applicationlogs/all-applicationlogs',
        title: 'MENU.APPLICATIONLOGS',
        iconType: 'material-icons-two-tone',
        icon: 'people_alt',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
      },   */
      {
        path: '/administration/settings',
        title: 'MENU.SETTINGS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
      }
    ]
  },
  {
    path: '',
    title: 'MENU.PROMOTIONS',
    iconType: 'material-icons-two-tone',
    icon: 'money_off',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin', 'Stores'],
    submenu: [
      {
        path: '/promotions/discounts',
        title: 'MENU.DISCOUNTS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin', 'Stores'],
        submenu: [],
      },
      {
        path: '/promotions/subscriptions',
        title: 'MENU.SUBSCRIPTION',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin', 'Stores'],
        submenu: [],
      },
      {
        path: '/promotions/creditpoints',
        title: 'MENU.CREDITPOINTS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin', 'Stores'],
        submenu: [],
      }
/*       ,
      {
        path: '/promotions/teacherrates',
        title: 'MENU.TEACHERRATES',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
      } */
      ,
      {
        path: '/promotions/storerates',
        title: 'MENU.STORERATES',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'MENU.CMS',
    iconType: 'material-icons-two-tone',
    icon: 'local_library',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [
      {
        path: '/cms/pages',
        title: 'MENU.PAGES',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
      },
      {
        path: '/cms/seos',
        title: 'MENU.SEOS',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Admin'],
        submenu: [],
      }
    ]
  },
  {
    path: '/products',
    title: 'MENU.PRODUCTS',
    iconType: 'material-icons-two-tone',
    icon: 'group_work',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin', 'Stores'],
    submenu: [],
  },
  {
    path: '/stores',
    title: 'MENU.STORES',
    iconType: 'material-icons-two-tone',
    icon: 'group_work',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  },
  {
    path: '/customers',
    title: 'MENU.CUSTOMERS',
    iconType: 'material-icons-two-tone',
    icon: 'group_add',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  },
  {
    path: '/vendors',
    title: 'MENU.VENDORS',
    iconType: 'material-icons-two-tone',
    icon: 'group_add',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  },

  {
    path: '/orders',
    title: 'MENU.ORDERS',
    iconType: 'material-icons-two-tone',
    icon: 'monetization_on',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin', 'Stores'],
    submenu: [],
  },
  {
    path: '/calendar',
    title: 'MENU.LIVEEVENTS',
    iconType: 'material-icons-two-tone',
    icon: 'event',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin', 'Stores'],
    submenu: [],
  },
  {
    path: '/enquirys',
    title: 'MENU.ENQUIRYS',
    iconType: 'material-icons-two-tone',
    icon: 'help',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  }
  ,
  {
    path: '/reports',
    title: 'MENU.REPORTS',
    iconType: 'material-icons-two-tone',
    icon: 'view_list',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin', 'Stores'],
    submenu: [],
  }
];
