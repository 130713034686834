import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { UnsubscribeOnDestroyAdapter } from './UnsubscribeOnDestroyAdapter';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs'; 

@Injectable()

export class CommonsService extends UnsubscribeOnDestroyAdapter {
  private readonly API_URL = environment.apiUrl ;
  private readonly API_TAG ="/common" ;  
  constructor(private httpClient: HttpClient) {
    super();
  }  

  getSysLookup(code: string): Observable<any> {    
    return this.httpClient.get<any>(this.API_URL+ this.API_TAG+ "/GetSysLookupData/" + code);    
  }
  
  getAutocomplete(filterkey: string,filterfieldname: string,filtertable: string,extrafiled: string,extrafiledvalue: string,extrafiled1: string,extrafiledvalue1: string): Observable<any> {    

    let arr={
      filterkey:filterkey.toString(),
      filterfieldname: filterfieldname.toString(),
      filtertable: filtertable.toString(),
      extrafiled: extrafiled.toString(),
      extrafiledvalue: extrafiledvalue.toString(),
      extrafiled1: extrafiled1.toString(),
      extrafiledvalue1: extrafiledvalue1.toString()
    };        
    return this.httpClient.post<[]>(this.API_URL+this.API_TAG +"/GetAutocomplete",arr);      
  }

}
